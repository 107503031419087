import { Box, Text } from '@asktia/tia-ui'

export const LANotificationBanner = () => {
    return (
        <Box
            sx={{
                width: '100vw',
                backgroundColor: '#ef308d',
                textAlign: 'center',
                position: 'relative',
                left: '50%',
                right: '50%',
                marginLeft: '-50vw',
                marginRight: '-50vw',
                height: '94px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '0 16px'
            }}
        >
            <Text
                sx={{
                    color: '#000',
                    fontSize: '18px',
                    margin: 0,

                    paddingLeft: '60px',
                    paddingRight: '60px'
                }}
            >
                Due to wildfires in Los Angeles,{' '}
                <b>
                    we are not charging no show or cancelation fees in LA at
                    this time.
                </b>
            </Text>
        </Box>
    )
}
